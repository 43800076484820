<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

DatePicker.methods.displayPopup = function () {
  this.position = {
    left: 0,
    top: "100%",
  };
};

import {
  required,
  email
} from "vuelidate/lib/validators";
import moment from 'moment';

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Edit User",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      title: "Form Edit User",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "User",
          href: "/user"
        },
        {
          text: "Create User",
          active: true
        }
      ],
      form: {
        firstname: null,
        lastname: null,
        phone: null,
        email: null,
        gender: "male",
        birthDate: null,
        address: null
      },
      typesubmit: false,
      isError: false,
      errorMessage: null,
      genders: ['male','female','other'],
      isDateNotSelected: false
    };
  },
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      phone: { required },
      email: { required, email },
      address: { required }
    }
  },
  mounted() {
    this.fetchUser()
  },
  methods: {
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {
        this.isDateNotSelected = this.form.birthDate === null ? true : false;

        if (this.isDateNotSelected) {
          return false;
        }

        // do your submit logic here
        let phoneNumber = null
        if (this.form.phone.toString().substring(0,2) == "62"){ 
          phoneNumber = `+${this.form.phone.toString()}`
        } else if (this.form.phone.toString().charAt(0) == "0"){ 
          phoneNumber = `+62${this.form.phone.toString().substring(1)}`
        } else {
          phoneNumber = `+62${this.form.phone.toString()}`
        }

        let param = new FormData();
        param.append('firstname', this.form.firstname) 
        param.append('lastname', this.form.lastname)
        param.append('email', this.form.email)
        param.append('phone', phoneNumber)
        param.append('gender', this.form.gender)
        if (this.form.birthDate) {
          param.append('birthDate', moment(this.form.birthDate).format("YYYY-MM-DD"))
        }
        if (this.form.address) {
          param.append('address', this.form.address)
        }

        this.$axios.put(`/user/${this.$route.params.id}`, param)
        .then( (result) => { 
          console.log(result.data)
          Swal.fire("Success", "User Edited Successfully", "success");
          this.$router.push('/user')
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },
    // get detail user
    fetchUser() {
      this.$axios.get(`/user/${this.$route.params.id}`)
        .then( (response) => { 
          const result = response.data.result

          let phoneNumber = null
          if (result.user.phone.toString().substring(0,3) == "+62") {
            phoneNumber = result.user.phone.toString()
          } else if(result.user.phone.toString().substring(0,2) == "62"){ 
            phoneNumber = `+${result.user.phone.toString()}`
          } else if(result.user.phone.toString().charAt(0) == "0"){ 
            phoneNumber = `+62${result.user.phone.toString().substring(1)}`
          } else {
            phoneNumber = `${result.user.phone.toString()}`
          }

          let birthDate = null

          if (result.user.birthDate) {
            birthDate = new Date(moment(result.user.birthDate).format("YYYY-MM-DD"))
          }
          
          this.form = {
            firstname: result.user.firstname,
            lastname: result.user.lastname,
            phone: phoneNumber.substring(3),
            email: result.user.email,
            gender: result.user.gender,
            address: result.user.address,
            birthDate: birthDate
          }

        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>First Name</label>
                <input
                  v-model="form.firstname"
                  type="text"
                  class="form-control"
                  name="firstname"
                  :class="{ 'is-invalid': typesubmit && $v.form.firstname.$error }"
                />
                <div v-if="typesubmit && $v.form.firstname.$error" class="invalid-feedback">
                  <span v-if="!$v.form.firstname.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Last Name</label>
                <input
                  v-model="form.lastname"
                  type="text"
                  class="form-control"
                  name="lastname"
                  :class="{ 'is-invalid': typesubmit && $v.form.lastname.$error }"
                />
                <div v-if="typesubmit && $v.form.lastname.$error" class="invalid-feedback">
                  <span v-if="!$v.form.lastname.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Phone</label>
                <b-input-group prepend="+62">
                  <input
                    v-model="form.phone"
                    type="number"
                    class="form-control"
                    name="phone"
                    :class="{ 'is-invalid': typesubmit && $v.form.phone.$error }"
                  />
                  <div v-if="typesubmit && $v.form.phone.$error" class="invalid-feedback">
                    <span v-if="!$v.form.phone.required">This value is required.</span>
                  </div>
                </b-input-group>
              </div>

              <div class="form-group">
                <label>Gender</label> 
                <multiselect v-model="form.gender" deselect-label="Can't remove this value" placeholder="Select one" :options="genders" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option | capitalize }}</strong></template>
                </multiselect>
              </div>

              <div class="form-group">
                <label>Date of Birth</label>
                <br />
                <date-picker
                  v-model="form.birthDate"
                  format="DD MMMM YYYY"
                  name="date"
                  :first-day-of-week="1"
                  lang="en"
                  placeholder="Select One"
                  :class="{ 'is-invalid': isDateNotSelected }"
                ></date-picker>
                <div v-if="isDateNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div> 

              <div class="form-group">
                <label>Address</label> 
                <textarea
                  v-model="form.address"
                  class="form-control"
                  name="address"
                  :class="{ 'is-invalid': typesubmit && $v.form.address.$error }"
                ></textarea>
                <div v-if="typesubmit && $v.form.address.$error" class="invalid-feedback">
                  <span v-if="!$v.form.address.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>E-Mail Address</label>
                <div>
                  <input
                    v-model="form.email"
                    type="email"
                    name="email"
                    class="form-control"
                    :class="{ 'is-invalid': typesubmit && $v.form.email.$error }"
                  />
                  <div v-if="typesubmit && $v.form.email.$error" class="invalid-feedback">
                    <span v-if="!$v.form.email.required">This value is required.</span>
                    <span v-if="!$v.form.email.email">This value should be a valid email.</span>
                  </div>
                </div>
              </div>

              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>